import {registerEvent, unregisterEvent} from "@/utils/custom-events";
import {useEffect, useState} from "react";

const TABLET_SIZE = 768;

export default () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < TABLET_SIZE);

  useEffect(() => {
    const isViewportMobile = () => {
      setIsMobile(window.innerWidth < TABLET_SIZE);
    };

    registerEvent("DOMContentLoaded", isViewportMobile);
    registerEvent("resize", isViewportMobile);

    return () => {
      unregisterEvent("resize", isViewportMobile);
    };
  }, []);

  return isMobile;
};
